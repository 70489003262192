<template>
  <div class="place-detail-public">
    <el-row :gutter="24">
      <el-col id="detail-content" :md="listImage.length ? 18 : 24" :sm="listImage.length ? 14 : 24" :xs="24">
        <div class="content mb-1" :style="`max-height: ${listImage.length ? `${(previewImageHeight + 5)}px` : '400px' }`">
          <el-row type="flex" align="middle" class="mb-3">
            <button class="origin-artifact" @click="handleReloadData"><i class="fas fa-home" /></button>
            <h4 class="content__title text-limited my-0">
              {{ dataDetail ? dataDetail.name : "" }}
            </h4>
          </el-row>
          <el-row type="flex" align="middle" class="content__action">
            <el-button
              v-if="dataDetail && dataDetail.vrtour"
              type="primary"
              class="text-uppercase btn mr-1 mb-2"
              @click="handleShowVrTour"
            >
              {{ $t("shared.watch3d") }}
            </el-button>
            <el-button
              v-if="dataDetail && dataDetail.audio && dataDetail.audio.url"
              type="primary"
              class="text-uppercase mb-2 btn"
              @click="handleSetAudioSrc"
            >
              {{ $t("shared.listenAudio") }}
            </el-button>
          </el-row>
          <audio
            v-if="isShowAudio"
            id="my-audio"
            :src="audioSrc"
            :type="contentType"
            controls
            controlslist="nodownload"
            autoplay
          />
          <div class="posts" v-html="dataDetail.content" />
        </div>
        <el-button type="text" @click="visibleContent = true">{{ $t('shared.viewDetail') }}</el-button>
      </el-col>
      <el-col v-if="!loadingImage && listImage.length" id="preview-image" :md="listImage.length ? 6 : 24" :sm="listImage.length ? 10 : 24" :xs="24">
        <splide ref="primary" :options="primaryOptions">
          <splide-slide v-for="(slide, index) in listImage" :key="slide">
            <img :src="slide" @click="handleShowImage(index)" />
          </splide-slide>
        </splide>
        <splide ref="secondary" :options="secondaryOptions">
          <splide-slide v-for="slide in listImage" :key="slide">
            <img :src="slide" />
          </splide-slide>
        </splide>
      </el-col>
    </el-row>
    <div v-if="artifacts.length">
      <el-row :gutter="24" class="mt-5 pt-5 list-artifact">
        <el-col
          v-for="item in artifacts"
          :key="item.id"
          :xs="24"
          :sm="12"
          :md="6"
          :lg="4"
          class="mb-4"
        >
          <card-item :data="item" @showDetail="showDetailArtifact" />
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" class="mb-5 mt-2">
        <el-pagination
          :current-page="page"
          :page-size="limit"
          :total="total"
          background
          layout="prev, pager, next"
          @current-change="handleChangePage"
        ></el-pagination>
      </el-row>
    </div>
    <show-vr-tour
      :visible="visibleVrTour"
      :url="selectedVrTour"
      @close="visibleVrTour = false"
    />
    <show-image
      :visible="visibleImage"
      :list-image="listImage"
      :selected-index="selectedIndex"
      @close="visibleImage = false"
    />
    <show-content
      :visible="visibleContent"
      :data="dataDetail"
      @close="visibleContent = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { listStorages } from '@/services/public/storage'
import { getPlaceById } from '@/services/public/place'
import { listPlaceArtifacts, getArtifactById } from '@/services/public/artifact'
import CardItem from '../components/Card.vue'
import ShowImage from '../components/popup/ShowImage.vue'
import ShowVrTour from '../components/popup/ShowVrTour.vue'
import ShowContent from '../components/popup/ShowContent.vue'
import i18n from '@/utils/i18n'
export default {
  name: 'ArtifactDetailPublic',
  components: {
    CardItem,
    ShowImage,
    ShowVrTour,
    ShowContent
  },
  data() {
    return {
      loadingImage: false,
      isShowAudio: false,
      audioSrc: '',
      contentType: '',
      placeName: '',

      dataDetail: {},
      listImage: [],
      allArtifacts: [],

      total: 0,
      limit: 6,
      page: 1,

      visibleImage: false,
      visibleVrTour: false,
      visibleContent: false,

      selectedVrTour: '',

      primaryOptions: {
        type: 'loop',
        width: '100%',
        pagination: false,
        arrows: false
      },
      secondaryOptions: {
        type: 'slide',
        rewind: true,
        width: '100%',
        gap: '1rem',
        pagination: false,
        cover: true,
        focus: 'center',
        isNavigation: true,
        perPage: 4,
        arrows: false
      },
      selectedIndex: 0,
      previewImageHeight: 0,
      contentHeight: 0
    }
  },
  computed: {
    ...mapGetters(['language']),
    artifacts() {
      return this.allArtifacts.slice((this.page - 1) * 6, (this.page * 6))
    }
  },
  beforeMount() {
    if (this.$route.query.locale) {
      i18n.locale = this.$route.query.locale
    }
    this.loadData()
    this.loadImage()
  },
  mounted() {
    window.addEventListener('resize', () => {
      const previewImage = document.getElementById('preview-image')
      const content = document.getElementById('detail-content').offsetHeight
      if (previewImage && content) {
        this.previewImageHeight = document.getElementById('preview-image').offsetHeight
        this.contentHeight = document.getElementById('detail-content').offsetHeight
      }
    })
  },
  methods: {
    handleReloadData() {
      this.loadData()
      this.loadImage()
    },
    loadData() {
      const params = {
        id: this.$route.params.id,
        locale: this.$route.query.locale ? this.$route.query.locale : this.language
      }
      getArtifactById(params).then((response) => {
        this.dataDetail = response
        this.loadPlace()
        this.loadArtifacts()
      })
    },
    loadPlace() {
      const params = {
        id: this.dataDetail.place_id,
        locale: this.$route.query.locale ? this.$route.query.locale : this.language
      }
      getPlaceById(params).then((response) => {
        this.placeName = response.name
      })
    },
    loadImage(id) {
      this.loadingImage = true
      const params = {
        object_id: id || this.$route.params.id,
        object_type: 'artifact',
        file_type: 'image',
        locale: this.$route.query.locale ? this.$route.query.locale : this.language
      }
      listStorages(params).then((response) => {
        this.listImage = response.result.map((i) => i.url)
        this.loadingImage = false
        if (this.listImage.length) {
          this.$nextTick(() => {
            this.$refs.primary.sync(this.$refs.secondary.splide)
          })
          setTimeout(() => {
            this.previewImageHeight = document.getElementById('preview-image').offsetHeight
            this.contentHeight = document.getElementById('detail-content').offsetHeight
          }, 100)
        }
      })
    },

    async loadArtifacts() {
      const params = {
        place_id: this.dataDetail.place_id,
        locale: this.$route.query.locale ? this.$route.query.locale : this.language,
        order: 'ordered',
        page: 1,
        per_page: 10000000
      }
      const res = await listPlaceArtifacts(params)
      const requests = res.result.map((r) => {
        return getArtifactById({
          id: r.id,
          locale: params.locale
        })
      })
      await Promise.all(requests).then((response) => {
        this.allArtifacts = response
        this.total = response.length
      })
    },

    handleChangePage(page) {
      this.page = page
    },

    showDetailArtifact(data) {
      this.dataDetail = data
      this.loadImage(data.id)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },

    handleSetAudioSrc() {
      if (this.dataDetail.audio && this.dataDetail.audio.url) {
        if (this.isShowAudio) {
          if (document.getElementById('my-audio').src === this.dataDetail.audio.url) {
            this.isShowAudio = false
          } else {
            document.getElementById('my-audio').setAttribute('src', this.dataDetail.audio.url)
            document.getElementById('my-audio').setAttribute('content_type', this.dataDetail.audio.content_type)
          }
        } else {
          this.audioSrc = this.dataDetail.audio.url
          this.contentType = this.dataDetail.audio.content_type
          this.isShowAudio = true
        }
      }
    },

    handleShowImage(index) {
      this.selectedIndex = index
      this.visibleImage = true
    },

    handleShowVrTour() {
      this.selectedVrTour = this.dataDetail.vrtour
      this.visibleVrTour = true
    }
  }
}
</script>

<style lang="scss" scoped>
.place-detail-public {
  background-color: #f1f1f1;
  padding: 60px;
  box-sizing: border-box;
  min-height: 100vh;
  .content {
    overflow: hidden;
    &__title {
      font-size: 20px;
      color: #333333;
      font-weight: 600;
    }
    &__action {
      .btn {
        padding: 8px 20px;
        font-size: 10px;
        font-weight: 700;
      }
    }
  }
}
.list-artifact {
  border-top: 1px solid #cccccc;
}
.origin-artifact {
  font-size: 22px;
  margin-right: 15px;
  color: #393E41;
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 8px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
</style>
<style lang="scss">
.place-detail-public {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-pagination {
    .el-pager {
      .number {
        height: auto;
        padding: 10px 25px;
        font-size: 16px;
        border-radius: 50rem;
        display: inline;
        color: #333;
        font-weight: 400;
        &.active {
          background-color: #ffcc01 !important;
          color: #333 !important;
        }
      }
    }
    .btn-prev {
      i {
        font-size: 18px !important;
      }
      background-color: transparent !important;
    }
    .btn-next {
      i {
        font-size: 18px !important;
      }
      background-color: transparent !important;
    }
  }
}
.splide--loop {
  position: relative;
  padding-top: 75%;
  .splide__track {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .splide__list {
      width: 100%;
      height: 100%;
      .splide__slide {
        width: 100% !important;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}
.splide--slide {
  margin-top: 20px;
  .splide__track {
    .splide__list {
      .splide__slide {
        aspect-ratio: 4/3;
      }
    }
  }
}
</style>

